import React, { Component } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  date = new Date();
  render() {
    return (
      <footer>
        <ul>
          <li className="nav-item active">
            <Link className="nav-link" to="/">
              Home <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/about">
              About <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/events">
              Events<span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/resources">
              Resources <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/contact">
              Contact <span className="sr-only">(current)</span>
            </Link>
          </li>
        </ul>

        <div className="copyInfo">
          {`Copyright  © ${this.date.getFullYear()}, blakforest.com`}
        </div>
      </footer>
    );
  }
}

export default Footer;
