import React, { useContext, useState, useEffect } from "react";
import { SortedLnkInfoContext, CategoriesContext } from "../contexts/LinkContext";
import "./about.scss";
import "./resources.scss";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ResourcePage = props => {
  const [Loading, setLoading] = useState(true);
  //const [linkInfo] = useContext(LinkContext);
  const [sortedLnkInfo] = useContext(SortedLnkInfoContext);
  const [categories] = useContext(CategoriesContext);
  var currentCateindex = 0;
  const [linkList, setLinkList] = useState("");
  const filterOnSelectChaged = (ev) => {
    currentCateindex = ev.target.value;
    setLinkList(sortedLnkInfo[currentCateindex]);
    console.log("event Testing", currentCateindex);
    console.log("sorrted", sortedLnkInfo[currentCateindex]);
    console.log(linkList);

  }
  useEffect(
    () => {
      if (sortedLnkInfo) {

        setLoading(false)

      }
    },
    [sortedLnkInfo],
  );
  //setLinkList(sortedLnkInfo[currentCateindex]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Blak Forest - Resource Page</title>
        <meta name="description" content="Site Listing, Education, Finance, Sponsorships,Scholarships " />
      </Helmet>
      <div id="secondary-page-heading" className="resource-bg bg-img">
        <div className="bg-overlay"></div>

        <h2>Resources</h2>
      </div>
      <div className="container-fluid main-body">


        {(Loading) ? <p className="load-text">loading<span>...</span></p> :
          <select id="selector" defaultValue="" className="form-control selection-filter" onChange={filterOnSelectChaged} >
            <option value="" disabled>Pick a Category</option>
            {categories.map((cates, index) => (
              <option key={index} value={index}>{cates}</option>

            ))}
          </select>}

        {linkList !== "" ? (<ul className="link-group"> {linkList.map((linkData, index) => (
          <li key={index} className="list-group-item tw-list"  >
            {" "}


            <h4><a href={linkData.url} target="_blank" rel="noopener noreferrer" > {linkData.name} </a></h4>
            <p>{linkData.description}</p>


          </li>
        ))}

        </ul>) : ""}
      </div>
    </React.Fragment >
  );
};
export default ResourcePage;
