import React, { useContext, } from "react";
import { LinkContext } from "../contexts/LinkContext";
import "./about.scss";
import { Helmet } from 'react-helmet';

const EventPage = props => {
  const [linkInfo] = useContext(LinkContext);
  console.log("Event Page", linkInfo);
  return (
    <React.Fragment>
      <Helmet>
        <title>Blak Forest - Events Page</title>
        <meta
          name="description"
          content="Blak Forest Local events date and venues" />

      </Helmet>
      <div id="secondary-page-heading" className="evts-bg bg-img">
        <div className="bg-overlay"></div>
        {/*<img src="./img/events_bg.jpg" height="200" width="1920" alt="" />*/}
        <h2>Events</h2>

      </div>
      <div className="container-fluid main-body">
        <ul className="link-group3">
          {linkInfo.map((linkData, index) => (

            (<li key={index} className="list-group-item">

              <h4><a target="_blank" rel="noopener noreferrer" href={linkData.url}>{linkData.name}</a></h4>
              <p>{linkData.description}</p>
            </li>
            )

          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};
export default EventPage;
