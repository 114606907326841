import React, { Component, useContext, useState } from "react";
import { LinkProvider, LinkContext } from "../contexts/LinkContext";
import "./about.scss";
import Helmet from 'react-helmet';

const AboutPage = props => {
  const [linkInfo, setLinks] = useContext(LinkContext);
  return (
    <React.Fragment>
      <Helmet>
        <title>Blak Forest - About Page</title>
        <meta
          name="description"
          content="Blak Forest a user resource for wide variety of information"

        />

      </Helmet>
      <div id="secondary-page-heading" className="about-bg img-bg">

        <div className="bg-overlay"></div>

        <h1>About</h1>
      </div>
      <div className="container-fluid main-body">

        <div className="main-body-textarea">

          <p>Welcome to Blakforest.com we are an online culture resource site.
          Our goal is to share resources with everyone. Our resources are available across canada.
          But for now the majority of the resources are available in Montreal.
          This will change in the near future.
            </p>
          <p>
            We are constantly updating our sources  weekly. Our resources range from design, technology, grants, films,
            festivals, events, scholarships, business, workshops, entrepreneurship and much more.
            If you have any resources you think that should be added contact us and we gladly add it to our site.
            Sometimes we have special deals and promo items, signup to our newsletter to receive these surprises!
            We are a group of Tech Nerd that believe that being well informed and having
            better options can help you make better decisions in life. Take some time and browse through our
            site you might find something you been looking for and share this online resource with someone else.
            Sharing is caring!

         </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutPage;
