import React from 'react';
import "./LinkList.scss";
const LinkList = (props) => {
    return (<ul className="link-group4"
    ><li className="link-heading"><h2>{props.category}</h2></li>
        {props.sites.map((site, siteIndex) => (
            <li key={site.id} className="list-group-item">
                {console.log(site.id)}
                <h4><a target="_blank" rel="noopener noreferrer" href={site.url}>{site.name}</a></h4>
                <p>{site.description}</p>
            </li>
        ))}</ul>);
}

export default LinkList;