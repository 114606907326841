import React from "react";
import { Link } from "react-router-dom";


const HomePage = () => {

  return (
    <React.Fragment>
      <div id="primary-page-heading">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="d-block w-100"
                src="./img/c.jpg"
                alt="First slide"
              />

              <div className="carousel-caption d-none d-md-block">
                <h5>Who are we</h5>
                <p>Welcome to Blakforest.com we are
                    an online culture resource site.</p>
                <Link className="btn btn-primary" to="/about">Learn more</Link>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src="./img/e.jpg"
                alt="Second slide"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Tech &amp; Tools</h5>
                <p>Discover our large and ever growing resources</p>
                <Link className="btn btn-primary" to="/resources">Learn more</Link>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src="./img/d.jpg"
                alt="Third slide"
              />
              <div className="carousel-caption d-none d-md-block">
                <h5>Contact Us</h5>
                <p>If you have any resources you think that should be added contact us
                </p>
                <Link to="/contacts" className="btn btn-primary">
                  Contact us
                </Link>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      <div className="container main-body">
        <div className="container article-previews">
          <div className="row justify-content-start">
            <div className="col-md-4">
              <div className="card">
                <div className="card-top">
                  <img src="./img/c.jpg" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Who are we</h5>
                  <h6 className="card-subtitle">Blakforest.com</h6>
                  <p className="card-text">
                    Welcome to Blakforest.com we are
                    an online culture resource site.
                </p>
                  <Link className="btn btn-primary" to="/about">Learn more</Link>



                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-top">
                  <img src="./img/a.jpg" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Tech &amp; Tools</h5>
                  <h6 className="card-subtitle">What is Unsplash?</h6>
                  <p className="card-text">
                    Unsplash is the internet’s source
                    of freely usable images
                </p>
                  <Link to="/tech_tools" className="btn btn-primary">
                    Learn more
                </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-top">
                  <img src="./img/d.jpg" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Contact Us</h5>
                  <h6 className="card-subtitle">Have any resources?</h6>
                  <p className="card-text">
                    If you have any resources you think that should be added contact us
                </p>
                  <Link to="/contacts" className="btn btn-primary">
                    Learn more
                </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="col-md-4">
              <div className="card">
                <div className="card-top">
                  <img src="./img/covidoutbreak_logo.png" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Health</h5>
                  <h6 className="card-subtitle">covid-19outbreak</h6>
                  <p className="card-text">
                    This public resource website is designed to help  share valuable information
                    concerning Covid-19 and resources available for the general public.
                </p>
                  <a className="btn btn-primary" target="_blank" rel="noopener noreferrer" href="https://covid-19outbreak.ca/">Learn more</a>



                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-top">
                  <img src="./img/card_img_blackonbfilms2.jpg" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Film</h5>
                  <h6 className="card-subtitle">Black On Black Films</h6>
                  <p className="card-text">
                    BLACK on BLACK FILMS is a Montreal film collective made up of directors, producers, screenwriters of Afro-descendant.
                    origins working to promote and develop an Afro-centric cinematographic corpus in Quebec.
                </p>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.blackonblackfilms.org/" className="btn btn-primary">
                    Learn more
                </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-top">
                  <img src="./img/card_img_blackbiblio.jpg" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Education</h5>
                  <h6 className="card-subtitle">blacbiblio.com</h6>
                  <p className="card-text">
                    The ABC’s of Canadian Black History is a pre-designed curriculum
                    enhancer tailored to...
                </p>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.blacbiblio.com/" className="btn btn-primary">
                    Learn more
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default HomePage;
