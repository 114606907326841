import React, { useState, createContext, useEffect } from "react";

export const LinkContext = createContext();
export const CategoriesContext = createContext();
export const SortedLnkInfoContext = createContext();
export const LinkProvider = (props) => {
  const [linkInfo, setLinks] = useState([{}]);
  const [categories, setCategories] = useState([]);
  const [sortedLnkInfo, setSortedLinks] = useState([[]]);
  const [fullquery, setQuery] = useState(
    "https://us-central1-blakforest-site.cloudfunctions.net/server/data"
  );
  useEffect(() => {
    async function fetchData() {
      // const response = await

      console.log("fetch calls: ");
      fetch(fullquery)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          var trimmedData = data.filter((x) => x !== null);
          setLinks(trimmedData);
          //setupCategories;
          var site_categories;

          site_categories = Array.from(
            new Set(trimmedData.map((item) => item.category))
          );
          //site_categories.unshift("NONE");
          setCategories(site_categories);

          var linkList = [];
          site_categories.forEach((category, index) => {
            linkList[index] = Array.from(
              trimmedData.filter(
                (site) => site.category === site_categories[index]
              )
            );
          });

          setSortedLinks(linkList);
        })
        .catch((error) => console.log("Fetch failed:" + error));
    }
    fetchData();
  }, [fullquery]);

  return (
    <LinkContext.Provider value={[linkInfo, setLinks]}>
      <CategoriesContext.Provider value={[categories, setCategories]}>
        <SortedLnkInfoContext.Provider value={[sortedLnkInfo, setSortedLinks]}>
          {props.children}
        </SortedLnkInfoContext.Provider>
      </CategoriesContext.Provider>
    </LinkContext.Provider>
  );
};
