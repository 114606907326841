import React, { Component, useContext } from "react";
//import logo from "./logo.svg";
import "./App.scss";
import "./responsive.scss";
import NavBar from "./components/navbar";
import AboutPage from "./components/about";
import ContactPage from "./components/contact";
import HomePage from "./components/home";
import EventPage from "./components/events";
import ResourcePage from "./components/resources";
import FilmPage from "./components/film";
import SideBar from "./components/sidebar";
import Footer from "./components/footer";
import TechTools from "./components/tech_tools";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { LinkProvider } from "./contexts/LinkContext";
import ReactGA from "react-ga";
import AdSense from "react-adsense";
ReactGA.initialize("UA-161742131-2");
ReactGA.pageview(window.location.pathname + window.location.search);
class App extends Component {
  state = {
    linkInfo: [{}],
  };
  componentWillMount() {
    ReactGA.initialize("UA-161742131-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log("initialize GA App.js");
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Home</title>
          <meta
            name="description"
            content="Blak Forest Home Page Endless information at the tips of your
          finger"
          />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <Router basename={process.env.PUBLIC_URL}>
          <NavBar />

          <div className="main-container">
            <Switch>
              <Route path="/" exact>
                <HomePage />
              </Route>

              <Route path="/about">
                <LinkProvider>
                  <AboutPage />
                </LinkProvider>
              </Route>

              <Route path="/events" exact>
                <LinkProvider>
                  <EventPage />
                </LinkProvider>
              </Route>

              <Route path="/resources" exact>
                <LinkProvider>
                  <ResourcePage />
                </LinkProvider>
              </Route>

              <Route path="/contacts" exact>
                <ContactPage />
              </Route>
              <Route path="/films" exact>
                <LinkProvider>
                  <FilmPage />
                </LinkProvider>
              </Route>
              <Route path="/tech_tools" exact>
                <LinkProvider>
                  <TechTools />
                </LinkProvider>
              </Route>
            </Switch>
            <div className="footer-ad">
              <AdSense.Google
                client="ca-pub-2119410864474374"
                slot="7352865589"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
                layoutKey="-gw-1+2a-9x+5c"
              />
            </div>
            <LinkProvider>
              <SideBar />
            </LinkProvider>
          </div>

          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
