import React, { useContext } from 'react';
import LinkList from '../components/LinkList';
import { LinkContext, SortedLnkInfoContext, CategoriesContext } from "../contexts/LinkContext";

import { Helmet } from 'react-helmet';
const FilmPage = (props) => {
    const [linkInfo] = useContext(LinkContext);
    const [sortedLinks] = useContext(SortedLnkInfoContext);
    const [categoryList] = useContext(CategoriesContext);
    return (
        <React.Fragment>
            <Helmet>
                <title>Blak Forest - Film Page</title>
                <meta name="description" content="Site Listing, Film Resources, Grants , Funding " />
            </Helmet>

            <div id="secondary-page-heading" className="evts-bg bg-img">
                <div className="bg-overlay"></div>
                <h2>Films</h2>

            </div>
            <div className="container-fluid main-body">

                {sortedLinks.map((siteList, index) => (
                    <LinkList key={index} sites={siteList} category={categoryList[index]} />


                ))}

            </div>
        </React.Fragment>
    );
}

export default FilmPage;