import React, { Component } from 'react';
import "./contact.scss";
import { Helmet } from 'react-helmet';

class ContactPage extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    formErrors: [],
    mailSent: false
  }
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Blak Forest Contact Us</title>
          <meta
            name="description"
            content="Blak Forest Contact Us Endless information at the tips of your
          finger"
          />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div id="secondary-page-heading" className="contact-bg bg-img">

          <div className="bg-overlay"></div>

          <h1>Contact Us</h1>
        </div>
        <div className="container-fluid main-body">
          <form id="contact-form" action="POST" onSubmit={this.handleSubmit}>
            <input type="text" placeholder="First Name" name="fName" id="first-name-txt" onChange={this.handleChange} />
            <input type="text" placeholder="Last Name" name="lName" id="last-name-txt" onChange={this.handleChange} />
            <input type="text" placeholder="email" name="email" id="email-txt" onChange={this.handleChange} />
            <textarea onChange={this.handleChange} name="messgae-body" placeholder="Message Body" id="msg-txtarea" cols="30" rows="10"></textarea>
            <input className="button blue" type="submit" value="Message US" />
          </form>
        </div>
      </>
    );
  }
  handleSubmit(evt) {

    evt.preventDefault();
    var target = evt.target;
    if (this.vaildateForm(target)) {
      fetch(`${process.env.PUBLIC_URL}/contact_sender.php`, {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then((request) => (request.json)
      ).then((response) => {
        console.log(response)
        console.log(this.state)
        if (response.status == 'success') {
          this.setState({ mailSent: true });
          console.log(response.code + "email sent")
        }
        else if (response.status == 'fail') {
          console.log(response.code + "email failed to send")
        }
      })
    }
  }

  handleChange(evt) {

    var target = evt.target;
    switch (target.name) {
      case "fName":
        this.setState({ firstName: target.value })
        break;
      case "lName":
        this.setState({ lastName: target.value })
        break;
      case "email":
        this.setState({ email: target.value })
        break;
      case "messgae-body":
        this.setState({ message: target.value })
        break;
      default:
        console.log("on Chnage Default  switch section called")
        break;
    }
  }
  vaildateForm(form) {
    return true;
    //TODO: vaildate form items and leave apporitae errors
  }

}

export default ContactPage;
