import React, { useContext, useState } from "react";
import { Helmet } from 'react-helmet';

import { SortedLnkInfoContext, CategoriesContext } from "../contexts/LinkContext";

const TechTools = (props) => {
    return (<React.Fragment>
        <Helmet>
            <title>Blak Forest - Tech Tools</title>
            <meta name="description" content="Blak Forest - Tech Tools: Best and most ubiquitous tools on the web " />
        </Helmet>
        <div id="secondary-page-heading" className="resource-bg bg-img">
            <div className="bg-overlay"></div>
            <h1>Tech & Tools</h1>

        </div>
        <div className="container-fluid main-body">
            <div className="main-body-textarea">
                <section className="tech_article">
                    <p style={{ width: "80%", fontSize: "1.1em" }}>Welcome to Tech and Tools page, on this page we will be write small reviews of softwares, tools and resources we use daily in our work.
</p>

                    <h4>Unsplash</h4>
                    <img class="img-showcase-full" src="./img/da_unsplash.jpg" alt="unsplash forest" />
                    <p style={{ width: "80%" }}>Founded in 2013 as a humble Tumblr blog, Unsplash has grown into an industry-leading visual community. It’s become a source of inspiration for everyone from award-winning writers like Deepak Chopra to
                industry-titans like Apple, and millions of creators worldwide.</p>
                    <p>Wanna learn more?</p> <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/" className="btn btn-primary"> check it out !
</a>

                </section>
                <section className="tech_article">
                    <h4>What is Trello</h4>
                    <img class="img-showcase-full" src="./img/trello_demo_board.png" alt="trello board" />
                    <p style={{ width: "80%" }}>Around the summer of 2010, Fog Creek Software starts doing regular Creek Weeks, internal explorations for potential products. In January 2011, a prototype that hopes to solve some high-level planning issues is pitched. It’s called Trellis. Full time development begins soon after.
After a closed beta, Trello launches at TechCrunch Disrupt in September of 2011 with apps for the web and iPhone. We narrowly avoid names like Cardvark and Planatee and go with the name Trello.</p>
                    <p>Wanna learn more?</p> <a target="_blank" rel="noopener noreferrer" href="https://trello.com/" className="btn btn-primary"> check it out !
</a>
                </section>
            </div>
        </div>
    </React.Fragment >);
}

export default TechTools;