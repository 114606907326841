import React, { useState, useContext } from "react";
import { LinkContext } from "../contexts/LinkContext";
import { link } from "fs";
import "./sidebar.scss";
import AdSense from "react-adsense";
const SideBar = () => {
  const [linkInfo, setLinks] = useContext(LinkContext);
  const [sideBarLinks, setupSidebar] = useState([{}]);

  console.log(linkInfo, "sidebar!!!!");
  return (
    <div className="sidebar">
      {console.log(linkInfo)}
      {<ul>
        {(linkInfo !== null && false) && linkInfo.slice(32, 35).map(link => (
          <li key={link.Id} className="side-item">
            {link.Name}
          </li>

        ))}
        <li className="center-banner">
          <a href="https://wpml.org/?aid=231209&affiliate_key=RkukRFvz9dCI" title="Turn your WordPress site multilingual"><img width="260" height="260" src="https://wpml.org/wp-content/themes/sitepress/banners/images/wpml_banner_v2_260x260_en.jpeg" alt="Multilingual WordPress" /></a>
        </li>

        <li className="center-banner">
          <AdSense.Google
            client="ca-pub-2119410864474374"
            slot="5935949939"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
        </li>
      </ul>}
    </div>
  );
};

export default SideBar;
