import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";

const Navbar = () => {
  return (

    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <Link className="navbar-brand mr-auto" to="/">
        <img src="img/blakforest_logo.jpg" alt="logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse ml-auto" id="navbarCollapse">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <Link className="nav-link" to="/">
              Home <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/about">
              About <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/events">
              Events <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/resources">
              Resources <span className="sr-only">(current)</span>
            </Link>
          </li>

          <li className="nav-item active">
            <Link className="nav-link" to="/films">
              Films <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/contacts">
              Contact <span className="sr-only">(current)</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>

  );
};

export default Navbar;
